import request from '@/utils/request'


// 查询传感器评论列表
export function listPcbaComments(query) {
  return request({
    url: '/pcbaComments/pcbaComments/list',
    method: 'get',
    params: query
  })
}

// 查询传感器评论分页
export function pagePcbaComments(query) {
  return request({
    url: '/pcbaComments/pcbaComments/page',
    method: 'get',
    params: query
  })
}

// 查询传感器评论详细
export function getPcbaComments(data) {
  return request({
    url: '/pcbaComments/pcbaComments/detail',
    method: 'get',
    params: data
  })
}

// 新增传感器评论
export function addPcbaComments(data) {
  return request({
    url: '/pcbaComments/pcbaComments/add',
    method: 'post',
    data: data
  })
}

// 修改传感器评论
export function updatePcbaComments(data) {
  return request({
    url: '/pcbaComments/pcbaComments/edit',
    method: 'post',
    data: data
  })
}

// 删除传感器评论
export function delPcbaComments(data) {
  return request({
    url: '/pcbaComments/pcbaComments/delete',
    method: 'post',
    data: data
  })
}
